@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

.inp-radio {
	display: none !important;
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg);
	color: white;
	opacity: 0;
	height: 16px;
	width: 16px;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
	opacity: 1;
}

input#description {
	height: 100px;
}

span.important {
	color: red;
}

@layer base {

	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}


a {
	text-decoration: none !important;

}

.dropdown-container {
	border-radius: 9px !important;
}

.dropdown-content {
	/* max-height: 165px;
	overflow-y: auto; */
	/* z-index: 1001;
	position: absolute;
	background-color: red; */
	z-index: 100005 !important;
	position: relative;
	/* overflow-y: visible; */
	/* background-color: blue; */



}

.slick-slide .slick-list {
	display: flex;
}

.errormultiselect>.dropdown-container {
	border: 1px solid rgb(249 115 22 / var(--tw-text-opacity)) !important;
	color: rgb(249 115 22 / var(--tw-text-opacity)) !important;
}

.errormultiselect .dropdown-container {
	border: 1px solid rgb(249 115 22 / var(--tw-text-opacity)) !important;
	color: rgb(249 115 22 / var(--tw-text-opacity)) !important;
}

.scrollbarhide {
	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
}

.scrollbarhide::-webkit-scrollbar {
	width: 0.5rem;
}

.scrollbarhide::-webkit-scrollbar-thumb {
	background-color: transparent;
}

.scrollbarhide::-webkit-scrollbar-track {
	background-color: transparent;
}

.dropdown-content,
.dropdown-content .panel-content {
	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
}

.dropdown-content::-webkit-scrollbar,
.dropdown-content .panel-content::-webkit-scrollbar {
	width: 0.05rem;
}

.dropdown-content::-webkit-scrollbar-thumb,
.dropdown-content .panel-content::-webkit-scrollbar-thumb {
	background-color: transparent;
}

.dropdown-content::-webkit-scrollbar-track,
.dropdown-content .panel-content::-webkit-scrollbar-track {
	background-color: transparent;
}

.emethod div p {
	display: flex;
	margin-bottom: 0 !important;
	justify-content: space-around;
	align-items: center;
	padding-left: 10px;
}

.disableClass .dropdown-container {
	@apply !border-none !bg-gray-100;
}

/* If you want to set a border when the disableClass is not present */
:not(.disableClass) .dropdown-container {}

input[type="radio"] {
	display: block !important;
}

select {
	@apply bg-white;
}

@media only screen and (max-width: 425px) {
	.resserc {
		width: 100% !important;
	}
}

.chakra-modal__content-container.css-1oxhx2p {
	z-index: 20 !important;
}

.css-1nmdiq5-menu {

	/* display: inline-block; */
	z-index: 50000 !important;
	position: absolute !important;
	overflow: visible !important;
	/* max-height: 150px !important; */


}



.p-inputtext {
	font-size: 0.9rem !important
}

.p-dropdown-filter {
	padding: 0.7rem !important;
}

/* .p-dropdown-panel {
	display: none;
} */

.react-datepicker__time-container {
	margin-top: -265px !important;
}


.tooltip_custom {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
  }
  
  .tooltip_custom .tooltiptext_custom {
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
  
	/* Position the tooltip */
	position: absolute;
	z-index: 1;
  }
  
  .tooltip_custom:hover .tooltiptext_custom {
	visibility: visible;
  }